import { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDiscoverGenre } from "./discoverSlice";
import "./discoverGenreSelector.css";

export const DiscoverGenreSelector = ({ onGenreChange }) => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  const genres = [
    "science fiction", "fantasy", "mystery", "thriller", "romance","adventure", "horror",
    "historical fiction", "western", "literary",  "dystopian",
    "steampunk", "space opera", "first contact", "post-apocalyptic",
    "alternative history", "epic", "urban fantasy", "magical realism",
    "cyberpunk", "superhero", "satire", "heist", "spy"
  ];

  useEffect(() => {
    dispatch(setDiscoverGenre(selectedGenre ? [selectedGenre] : []));
  }, [selectedGenre, dispatch]);

  const handleGenreClick = (genre) => {
    setSelectedGenre((prevGenre) => (prevGenre === genre ? null : genre));
    onGenreChange && onGenreChange(selectedGenre ? [selectedGenre] : []);
  };

  useEffect(() => {
    const handleWheelScroll = (event) => {
      if (scrollRef.current) {
        event.preventDefault();
        scrollRef.current.scrollLeft += event.deltaY; // Makes mouse wheel scroll horizontally
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleWheelScroll, { passive: false });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("wheel", handleWheelScroll);
      }
    };
  }, []);

  return (
    <div className="genre-selector-container">
      <div className="scroll-wrapper">
        <div className="scroll-container" ref={scrollRef}>
          {genres.map((genre) => (
            <div
              key={genre}
              className={`genre-icon-container ${selectedGenre === genre ? "selected" : ""}`}
              onClick={() => handleGenreClick(genre)}
            >
              <img
                loading="lazy"
                className="genre-selector-icon"
                alt={genre}
                src={`/images/genre-icons/${genre}.png`}
                title={genre}
              />
              {selectedGenre === genre && <span className="dot-selector"></span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
