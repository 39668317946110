import { createSlice } from "@reduxjs/toolkit";


export const discoverSlice = createSlice({
    name: 'discover',
    initialState: {
        genre: []
    },
    reducers: {
        setDiscoverGenre: (state,action) => {
            state.genre = action.payload
        }
      
    }
})

export const selectDiscoverGenre = (state) => state.discover.genre
export const {setDiscoverGenre} = discoverSlice.actions
export default discoverSlice.reducer;
