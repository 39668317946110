import React, { useEffect } from 'react';
import { Nav } from '../app/nav/nav';
import { Outlet } from 'react-router-dom';
import PageViewTracker from './pageViewTracker';
import { useDispatch, useSelector } from "react-redux";
import { fetchAppData} from './appSlice'; // Import setUsers action
import { useAuth } from "../contexts/authContext/authContext";
import './App.css'
import { Footer } from './footer/footer';
import { Motto } from '../pages/home/motto';
import ScrollToTop from '../utilities/scrollToTop';

function App() {
    const { currentUser } = useAuth();
    const dispatch = useDispatch();
    const { users, stories, activity, contests, following, isLoading, error, user, forumQuestions,forumAnswers,forumComments, potds, news, userPrompts } = useSelector(state => state.app);

    const data = {
        users,
        stories,
        activity,
        contests,
        user,
        error,
        isLoading,
        following,
        forumAnswers,
        forumComments,
        forumQuestions,
        potds,
        news,
        userPrompts
    };

    // Fetch app data on component mount or when currentUser changes
    useEffect(() => {
        dispatch(fetchAppData());
    }, [dispatch, currentUser]);

    

    return (
        <div>
            <div className="app" >
                
                {isLoading && <div className="loading-screen">
                    <div className="loading-overlay"></div>
                    <img src="/images/brand/favlogotrans.png" alt="Loading..." className="loading-image" loading="lazy" />
                </div>}
                <div className='Nav'>
                    <Nav />
                </div>
                <PageViewTracker />

                {/* currently not working */}
                <ScrollToTop />
                
                <div className='mainOutlet'>
                    <Outlet context={data} />
                    <div className="motto-container">
                        <Motto />
                    </div>
                    <Footer />
                </div>
            </div>
            
            
            
        </div>
    );
}

export default App;
