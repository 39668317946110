import React from "react";
import { BlogCarousel } from "../blog/blogCarousel";
import './home.css'
import { Leaderboard } from "./leaderboard/leaderboard";
import { OpenContests } from "./openContests/openContests";
import { News } from "./news/news";
import { DiscoverStories } from "./discoverStories/discoverStories";
import { HomePotd } from "./homePotd";
import { FeaturedPotd } from "../potd/featuredPotd";
import { MerchCarousel } from "./merchCarousel/merchCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBlog, faBook, faBullseye, faList, faNewspaper, faPen, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { SearchBar } from "./search/search";
import { Quote } from "./quote";

export const Home = () => {
    
    return(
        <div className = 'home'>

            <div className="center">
                <div className="mobile-title"><h1>Discover Stories</h1></div>
                <DiscoverStories />
                <h3 id='mobile-title'className= 'home-subheader'><FontAwesomeIcon icon={faBlog}/> On the Blog</h3>
                <BlogCarousel />
                <h3 id='mobile-title'className= 'home-subheader'><FontAwesomeIcon icon={faBullseye}/> Open Contests</h3>
                <OpenContests />
                <h3 id='mobile-title'className= 'home-subheader'><FontAwesomeIcon icon={faNewspaper}/> Writician News</h3>
                <News />
            </div>
            
            <div className="right">
                <div className="wide-search"><SearchBar /></div>
                <div className="leaderboard sub-section">
                    <h3 className= 'home-subheader'><FontAwesomeIcon icon={faList}/> Leaderboard</h3>
                    <Leaderboard />
                </div>
                
                <div className="feature sub-section">
                    <h3 className= 'home-subheader'><FontAwesomeIcon icon={faBook}/> Featured Story</h3>
                    <FeaturedPotd />
                </div>
                <div className="homePotd sub-section">
                    <h3 className= 'home-subheader'><FontAwesomeIcon icon={faPen}/> Prompt of the Day</h3>
                    <HomePotd />
                </div>
                <div className="merch sub-section">
                    <h3 className= 'home-subheader'><FontAwesomeIcon icon={faShoppingBasket}/> Merch</h3>
                    <MerchCarousel />
                </div>
                <div className="quote sub-section">
                    <Quote />
                </div>
                {/* <Footer /> */}
            </div>
        </div>
        
    )
}

